import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanPrograms";
import Resources from "../components/Repeating/Resources";
import About from "../components/Repeating/About";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonIcon from "../components/Button/ButtonIcon";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Purchase | Full-Service Mortgage Lender | Right Start"
        description="Purchase a home in 4 easy steps: get prequalified, select the right loan program, find a great interest rate, and close your loan. It's that simple. Call today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Purchase/Hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>When You’re Ready To Buy a Home</h1>
              <p>
                Right Start Mortgage is a smart start for home buying because
                you'll have trusted experts by your side to guide you. We'll
                help you get prequalified, select the right loan program, find a
                great interest rate, and close your loan. You'll get the keys to
                your new home in no time!
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <LoanPrograms className="mb-0" />

      <section className="mb-20 bg-primary-50 pb-20 md:mb-32 md:pb-32">
        <div className="container">
          <header className="mb-12 text-center md:mb-16">
            <h2>Mortgage Rate Options</h2>
          </header>
          <div className="grid gap-10 md:grid-cols-2 md:gap-12">
            <Link
              to="/fixed-rate-mortgage/"
              className="group rounded-lg bg-white p-6 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <h3 className="heading-four">Fixed Rate</h3>
              <p>
                You’ll have a fixed interest rate for the entire loan term from
                beginning to end.
              </p>
              <ButtonIcon
                href="/fixed-rate-mortgage/"
                text="Learn More"
                altStyle={2}
              />
            </Link>
            <Link
              to="/adjustable-rate-mortgage/"
              className="group rounded-lg bg-white p-6 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <h3 className="heading-four">Adjustable-Rate Mortgage (ARM)</h3>
              <p>
                You’ll have an initial fixed interest rate for a set period, and
                then the rate will become variable.
              </p>
              <ButtonIcon
                href="/adjustable-rate-mortgage/"
                text="Learn More"
                altStyle={2}
              />
            </Link>
            <Link
              to="/interest-only-mortgage/"
              className="group rounded-lg bg-white p-6 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <h3 className="heading-four">Interest-Only Loan</h3>
              <p>
                You’ll pay interest only for the first several years, which
                lowers your monthly payments for that initial period.
              </p>
              <ButtonIcon
                href="/interest-only-mortgage/"
                text="Learn More"
                altStyle={2}
              />
            </Link>
            <Link
              to="/graduated-payment-mortgage/"
              className="group rounded-lg bg-white p-6 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <h3 className="heading-four">Graduated Payment Mortgage</h3>
              <p>
                Your monthly payments will start low initially and gradually
                increase over time.
              </p>
              <ButtonIcon
                href="/graduated-payment-mortgage/"
                text="Learn More"
                altStyle={2}
              />
            </Link>
          </div>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <About />
      <StatesWeServe />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
